<template>
  <div id="sendOrderModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Укажите номер телефона</h5>
        </div>
        <div class="modal-body">
          <div class="form-group form-horizontal">
            <input type="hidden" id="send-type" v-model="sendType" />
            <input
              class="form-control"
              type="tel"
              v-model="phone"
              v-validate="{ required: true, regex: /^\+7 \(\d\d\d\) \d\d\d-\d\d\d\d/gm}"
              name="phone"
              id="phone"
              v-bind:class="getValidationClasses('phone')"
              v-mask="'+7 (###) ###-####'"
            />
            <br />
            <label
              for="phone"
              class="text-center"
            >Наши менеджеры свяжутся с Вами в самое ближайшее время.</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            v-on:click="sendOrder"
            :disabled="!isValid('phone')"
          >Отправить</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: "+7",
      sendType: "",
      summ: 0,
      tarif: '',
      period: ''
    };
  },
  methods: {
    sendOrder: function () {
      $("#sendOrderModal").modal("hide");
      //console.log('Sended ' + this.phone + '; Send type: ' + this.sendType + '; Summ ' + this.summ);

      var self = this;

      var jqxhr = $.post(
        "home/sendOrder",
        { phone: this.phone, summ: this.summ, button: this.sendType, tarif: this.tarif, period: this.period },
        function () {
          //console.log('Success');
          noty({ text: "Успешно отправлено.", type: "success" });
          if (yaCounter25303955) {
            var goalReached = "";
            switch (self.sendType) {
              case "summ":
                goalReached = "btnSumm";
                break;
              case "recall":
                goalReached = "btnRecall";
                break;
              case "zalog":
                goalReached = "btnZalog";
                break;
              case "bezzalog":
                goalReached = "btnBezzalog";
                break;
              default:
                goalReached = "btnSumm";
            }

            yaCounter25303955.reachGoal(goalReached);
          } else {
            console.log("YaCounter is not defined.");
          }
        }
      );

      jqxhr.fail(function () {
        //console.log('error');
        noty({ text: "При отправке произошла ошибка.", type: "error" });
      });

      this.phone = "+7";
    },
    getValidationClasses: function (field) {
      return {
        "is-valid":
          this.fields[field] &&
          this.fields[field].valid &&
          this.fields[field].dirty,
        "is-invalid":
          this.fields[field] &&
          this.fields[field].invalid &&
          this.fields[field].dirty,
      };
    },
    hasError: function (field) {
      return (
        this.fields[field] &&
        this.fields[field].invalid &&
        this.fields[field].dirty
      );
    },
    isValid: function (field) {
      return this.fields[field] && this.fields[field].valid;
    },
  },
};
</script>