<template>
    <div >
        <div v-show="!isFocused" class="form-control" @click="textClick" style="width: 200px">{{formatValue}}</div>
        <input v-show="isFocused" ref="input" class="form-control" :value="value" @input="onInput" @focus="onFocus" @blur="onBlur" />
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        formatValue: {
            required: true
        },
        minValue: {
            type: Number,
            required: false,
            default: 0
        },
        maxValue: {
            type: Number,
            required: false,
            default: Number.MAX_VALUE
        }
    },
    data() {
        return {
            isFocused: true,
            inputWidth: 0
        }
    },
    mounted(){
        this.$nextTick(() => {
            if (this.$refs.input) {
            this.inputWidth = this.$refs.input.clientWidth;
            console.log(this.inputWidth);
            this.isFocused = false;
        }
        })
        
    },
    methods: {
        textClick() {
            console.log('textClick')
            this.isFocused = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            })
        },
        onFocus() {
            this.isFocused = true;
        },
        onBlur() {
            this.isFocused = false;
        },
        onInput($evt) {
            let value = Number($evt.target.value);

            if (value > this.maxValue) {
                value = this.maxValue;
            }

            if (value < this.minValue) {
                value = this.minValue;
            }

            this.$emit('input', Number(value));
        }
    }
}
</script>