<template>
  <div id="sendOrderModal2" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title">Заявка на займ</h5>
        </div>
        <div class="modal-body">
          <input type="hidden" id="send-type" v-model="sendType" />
          <div class="row">
            <!--ФИО-->
            <div class="col-md-8 col-xs-12">
                <div class="form-group">
                  <label for="fio">ФИО</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="fio"
                    v-validate="{ required: true }"
                    name="fio"
                    id="fio"
                    v-bind:class="getValidationClasses('fio')"
                  />
                </div>
            </div>
            <!--Телефон-->
            <div class="col">
              <div class="form-group">
                <label for="phone2">Телефон</label>
                  <input
                    class="form-control form-control-sm"
                    type="tel"
                    v-model="phone"
                    v-validate="{ required: true, regex: /^\+7 \(\d\d\d\) \d\d\d-\d\d\d\d/gm}"
                    name="phone2"
                    id="phone2"
                    v-bind:class="getValidationClasses('phone2')"
                    v-mask="'+7 (###) ###-####'"
                  />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="tarif">Тип займа</label>
                <select v-model="tarif" class="form-control form-control-sm">
                    <optgroup v-for="g in calcGroups" :key="g.category" :label="g.category">
                        <option v-for="calc in g.calcs" :key=calc.name :value="calc.category + ' ' + calc.name">{{calc.category + ' ' + calc.name}}</option>
                    </optgroup>
                </select>
            
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-8 col-xs-12">
              <div class="form-group">
                <label for="summ">Сумма займа</label>
                <input
                  class="form-control form-control-sm"
                  type="number"
                  v-model="summ"
                  name="summ"
                  id="summ"
                />
            
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="period">Срок</label>
                <input
                  class="form-control form-control-sm"
                  type="text"
                  v-model="period"
                  name="period"
                  id="period"
                />
            
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col">
              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="extForm" v-model="showExtendedForms">
                <label class="form-check-label" for="extForm">Заполнить расширинную форму заявки для перевода на карту</label>
              </div>
            </div>
          </div>
          
          <div v-if="showExtendedForms">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="birthPlace">Место рождения</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.birthPlace"
                    name="birthPlace"
                    id="birthPlace"
                    v-bind:class="getValidationClasses('birthPlace')"
                    v-validate="{ required: true }"
                  />
            
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="address">Адрес прописки</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.address"
                    name="address"
                    id="address"
                    v-bind:class="getValidationClasses('address')"
                    v-validate="{ required: true }"
                  />
            
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="factAddress">Адрес фактического проживания</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.factAddress"
                    name="factAddress"
                    id="factAddress"
                    v-bind:class="getValidationClasses('factAddress')"
                    v-validate="{ required: true }"
                  />
            
                </div>
              </div>
            </div>

            <div class="row">
              <!--Паспорт серия-->
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label for="passSeries">Паспорт серия</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.passSeries"
                    v-validate="{ required: true, regex: /\d\d\d\d/gm }"
                    name="passSeries"
                    id="passSeries"
                    v-bind:class="getValidationClasses('passSeries')"
                    v-mask="'####'"
                  />
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label for="passNumber">Номер</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.passNumber"
                    v-validate="{ required: true, regex: /\d\d\d\d\d\d/gm }"
                    name="passNumber"
                    id="passNumber"
                    v-bind:class="getValidationClasses('passNumber')"
                    v-mask="'######'"
                  />
                </div>
              </div>
              <div class="col-md-4 col-xs-12">
                <div class="form-group">
                  <label for="passDate">Дата выдачи</label>
                  <!-- <v-date-picker v-model="passport.passDate" :popover="{ visibility: 'click' }" >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="form-control form-control-sm"
                        :value="inputValue"
                        v-on="inputEvents"
                        id="passDate" name="passDate" 
                        v-validate="{ required: true, Date: true }" 
                        v-bind:class="getValidationClasses('passDate')"
                        v-mask="'##.##.####'"
                      />
                    </template>
                  </v-date-picker> -->
                   <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.passDate"
                    v-validate="{ required: true, regex: /\d\d\.\d\d\.\d\d\d\d/gm }"
                    name="passDate"
                    id="passDate"
                    v-bind:class="getValidationClasses('passDate')"
                    v-mask="'##.##.####'"
                  /> 
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-8 col-sm-12">
                <div class="form-group">
                  <label for="passOrg">Кем выдан</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.passOrg"
                    name="passOrg"
                    id="passOrg"
                    v-bind:class="getValidationClasses('passOrg')"
                    v-validate="{ required: true }"
                  />
            
                </div>
              </div>
              <div class="col-lg-4 col-xs-12">
                <div class="form-group">
                  <label for="passSubdivision">Код подразделения</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="passport.passSubdivision"
                    v-validate="{ required: true, regex: /\d\d\d-\d\d\d/gm }"
                    name="passSubdivision"
                    id="passSubdivision"
                    v-bind:class="getValidationClasses('passSubdivision')"
                    v-mask="'###-###'"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
              <label
              class="text-center"
            >Наши менеджеры свяжутся с Вами в самое ближайшее время.</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            v-on:click="sendOrder"
            :disabled="!formPhoneValid()"
          >Отправить</button>
          <button type="button" class="btn btn-light" data-dismiss="modal">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import calcConfig from "../calcConfig";

export default {
  data() {
    return {
      phone: "+7",
      sendType: "",
      summ: 0,
      tarif: "",
      period: "",
      fio: "",
      showExtendedForms: false,
      passport: {
        birthPlace: '',
        address: '',
        factAddress: '',
        passSeries: '',
        passNumber: '',
        passDate: '',
        passOrg: '',
        passSubdivision: ''
      }
    };
  },
  computed: {
    calcGroups(){
        let a = calcConfig.map(i => { return { category: i.category }});

        var groups = [];
        a.forEach(function(item) {
            if(groups.find(i => i.category == item.category) == null) {
                groups.push(item);
            }
        });

        groups.forEach(cat => {
            cat.calcs = calcConfig.filter(i => i.category == cat.category);
        });
        return groups;
    }
  },
  methods: {
    sendOrder: function () {
      $("#sendOrderModal2").modal("hide");
      //console.log('Sended ' + this.phone + '; Send type: ' + this.sendType + '; Summ ' + this.summ);

      var self = this;

      var sendData = {
        phone: this.phone,
        summ: this.summ,
        button: this.sendType,
        tarif: this.tarif,
        period: this.period,
        fio: this.fio
      }

      if (this.showExtendedForms) {
        sendData.passport = this.passport;
      }

      var jqxhr = $.post(
        "home/sendOrder",
        sendData,
        function () {
          //console.log('Success');
          noty({ text: "Успешно отправлено.", type: "success" });
          if (yaCounter25303955) {
            var goalReached = "";
            switch (self.sendType) {
              case "summ":
                goalReached = "btnSumm";
                break;
              case "recall":
                goalReached = "btnRecall";
                break;
              case "zalog":
                goalReached = "btnZalog";
                break;
              case "bezzalog":
                goalReached = "btnBezzalog";
                break;
              default:
                goalReached = "btnSumm";
            }

            yaCounter25303955.reachGoal(goalReached);
          } else {
            console.log("YaCounter is not defined.");
          }
        }
      );

      jqxhr.fail(function () {
        //console.log('error');
        noty({ text: "При отправке произошла ошибка.", type: "error" });
      });

      this.phone = "+7";
    },
    getValidationClasses: function (field) {
      return {
        "is-valid":
          this.fields[field] &&
          this.fields[field].valid &&
          this.fields[field].dirty,
        "is-invalid":
          this.fields[field] &&
          this.fields[field].invalid &&
          this.fields[field].dirty,
      };
    },
    hasError: function (field) {
      return (
        this.fields[field] &&
        this.fields[field].invalid &&
        this.fields[field].dirty
      );
    },
    isValid: function (field) {
      return this.fields[field] && this.fields[field].valid;
    },
    formValid: function() {

      if (!(this.fields)) {
        return false;
      }

      for (const key in this.fields) {
          const field = this.fields[key];
          if (field.invalid) {
            return false;
          }
        
      }
      return true;
    },
    formPhoneValid: function() {
      return this.fields['phone2'] && this.fields['phone2'].valid;
    }
    
  },
};
</script>