import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import $ from 'jquery';
 
global.jQuery = $;
global.$ = $;

import './jquery.easing.1.3'

import './noty/jquery.noty';
import './noty/layouts/topRight';
import './noty/themes/default';
//import './template/creative/creative';

import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import VueTheMask from 'vue-the-mask'

import VCalendar from 'v-calendar'

import App from './App.vue';
import OlivinCalc from './components/OlivinCalc.vue';

Vue.use(VeeValidate);
Vue.use(VueTheMask);
Vue.use(VCalendar)

Validator.extend("Date", {
    getMessage: field =>  field + ' Не дата',
    validate: value => {
        console.log('Validate: ' + value);
        var str = value.toString();
        return str.match(/\d\d\.\d\d\.\d\d\d\d/) != null;
        //return value instanceof Date && !isNaN(value.valueOf())
    }
})

import SendOrderModal from './components/SendOrderModal.vue'
import SendOrderModal2 from './components/SendOrderModal2.vue'

Vue.config.productionTip = false

let calc;

if (process.env.NODE_ENV == 'development') {
    
    calc = new Vue({
        render: h => h(App),
      }).$mount('#calc').$children[0].$children[0];
} else
{
    
    calc = new Vue({
        render: h => h(OlivinCalc),
      }).$mount('#calc').$children[0];
}

$.noty.defaults = {
  layout: 'topRight',
  theme: 'defaultTheme',
  type: 'information',
  text: '',
  dismissQueue: true, // If you want to use queue feature set this true
  template: '<div class="noty_message"><span class="noty_text"></span><div class="noty_close"></div></div>',
  animation: {
      open: { height: 'toggle' },
      close: { height: 'toggle' },
      easing: 'swing',
      speed: 500 // opening & closing animation speed
  },
  timeout: false, // delay for closing event. Set false for sticky notifications
  force: false, // adds notification to the beginning of queue when set to true
  modal: false,
  closeWith: ['click'], // ['click', 'button', 'hover']
  callback: {
      onShow: function () { },
      afterShow: function () { },
      onClose: function () { },
      afterClose: function () { }
  },
  buttons: false // an array of buttons
};

/*var summDict = {
  '1': 2000,
  '2': 3000,
  '3': 4000,
  '4': 5000,
  '5': 6000,
  '6': 7000,
  '7': 8000,
  '8': 9000,
  '9': 10000,
  '10': 15000,
  '11': 20000,
  '12': 25000,
  '13': 30000,
  '14': 40000,
  '15': 50000,
  '16': 60000,
  '17': 70000,
  '18': 80000,
  '19': 90000,
  '20': 100000,
  '21': 200000,
  '22': 300000,
  '23': 400000,
  '24': 500000,
  '25': 600000,
  '26': 700000,
  '27': 800000,
  '28': 900000,
  '29': 1000000,
  '30': 2000000,
  '31': 3000000
};
var calcEl = $('#calc-wrapper');
if (calcEl.length > 0) {
  var calc = new Vue({
      el: '#calc-wrapper',
      data: {
          summ: 1,
          minValue: 1,
          maxValue: 31,
          countWeek: 8,
          weekPercent: 6.02
      },
      computed: {
          weekPayment: function () {
              var stavka = this.weekPercent / 100;
              var part1 = Math.pow((1 + stavka), this.countWeek);
              var part2 = Math.pow(part1 - 1, -1);
              return Math.round(this.summ * (stavka + stavka * part2));
          },
          result: function () {
              return summDict[this.summ];
          }
      },
      components: {
          vueSlider: window['vue-slider-component']
      },
      filters: {
          format: function (value) {
              if (!value) {
                  return '';
              }
              value = value.toString();
              var formatedValue = '';
              for (var i = 0; i < value.length; i++) {
                  if (i > 0 && (value.length - i) % 3 === 0) {
                      formatedValue += ' ';
                  }

                  formatedValue += value[i];
              }
              return formatedValue;
          }
      }
  });
}*/
/*var modal = new Vue({
  el: '#sendOrderModal',
  data: {
      phone: '+7',
      sendType: '',
      summ: 0
  },
  methods: {
      sendOrder: function () {
          $('#sendOrderModal').modal('hide');
          //console.log('Sended ' + this.phone + '; Send type: ' + this.sendType + '; Summ ' + this.summ);

          var self = this;

          var jqxhr = $.post('home/sendOrder', { phone: this.phone, summ: this.summ, button: this.sendType }, function () {
              //console.log('Success');
              noty({ text: 'Успешно отправлено.', type: 'success' });
              if (yaCounter25303955) {
                  var goalReached = '';
                  switch (self.sendType) {
                      case 'summ':
                          goalReached = 'btnSumm';
                          break;
                      case 'recall':
                          goalReached = 'btnRecall';
                          break;
                      case 'zalog':
                          goalReached = 'btnZalog';
                          break;
                      case 'bezzalog':
                          goalReached = 'btnBezzalog';
                          break;
                      default:
                          goalReached = 'btnSumm';
                  }

                  yaCounter25303955.reachGoal(goalReached);
              }
              else {
                  console.log('YaCounter is not defined.');
              }
          });

          jqxhr.fail(function () {
              //console.log('error');
              noty({ text: 'При отправке произошла ошибка.', type: 'error' });
          })

          this.phone = '+7';
      },
      getValidationClasses: function (field) {
          return {
              'is-valid': this.fields[field] && this.fields[field].valid && this.fields[field].dirty,
              'is-invalid': this.fields[field] && this.fields[field].invalid && this.fields[field].dirty
          };
      },
      hasError: function (field) {
          return this.fields[field] && this.fields[field].invalid && this.fields[field].dirty;
      },
      isValid: function (field) {
          return this.fields[field] && this.fields[field].valid;
      }
  }
});*/

let modal = new Vue({
    render: h => h(SendOrderModal),
  }).$mount('#sendOrderComponent').$children[0];

  let modal2 = new Vue({
    render: h => h(SendOrderModal2),
  }).$mount('#sendOrderComponent2').$children[0];

$('#olivin-carousel').on('slide.bs.carousel', function (event) {
  $('#blur-image').attr('xlink:href', event.relatedTarget.children[0].src);
});

$('#sendOrderModal').on('show.bs.modal', function (event) {
  var button = $(event.relatedTarget);
  var data = button.attr('data-send-type');

  /*var modalDialog = $(this);
  var input = modalDialog.find('#send-type');
  input.val(data);
  input.change();*/

  var calcValue = '';
  var tarif = '';
  var period = '';
  if (calc) {
      calcValue = calc.summ;
      tarif = calc.calc.category + ' ' + calc.calc.name;
      period = calc.periodStr;
  }

  modal.sendType = data;
  modal.summ = calcValue;
  modal.tarif = tarif;
  modal.period = period;

});

$('#sendOrderModal2').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var data = button.attr('data-send-type');
  
    /*var modalDialog = $(this);
    var input = modalDialog.find('#send-type');
    input.val(data);
    input.change();*/
  
    var calcValue = '';
    var tarif = '';
    var period = '';
    if (calc) {
        calcValue = calc.summ;
        tarif = calc.calc.category + ' ' + calc.calc.name;
        period = calc.periodStr;
    }
  
    modal2.sendType = data;
    modal2.summ = calcValue;
    modal2.tarif = tarif;
    modal2.period = period;
  
  });
  
