<template>
    <input type="range" class="custom-range" :min="min" :max="max" :step="step" :value="value" @input="chengeValue" />
</template>
<script>
export default {
    props: {
        min: {
            type: Number,
            required: false,
            default: 1
        },
        max: {
            type: Number,
            required: false,
            default: 100
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        value: {
            type: Number,
            required: false,
            default: 1
        }
    },
    methods: {
        chengeValue($evt){
            this.$emit('input', Number($evt.target.value));
        }
    }
}
</script>