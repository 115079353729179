<template>
  <div class="container">
    <olivin-calc></olivin-calc>
  </div>
</template>

<script>
import OlivinCalc from './components/OlivinCalc.vue';


export default {
  name: "App",
  components: {
    OlivinCalc
  }
};
</script>
