export default class {
    periodToStr(value, type){
        switch (type) {
            case 'day':
                return this.periodToStrDay(value);
            case 'week':
                return this.periodToStrWeek(value);
            case 'month':
                return this.periodToStrMonth(value);
        }
    }

    getPayment(summ, countPayments, staff, periodType) {
        if (periodType == 'day') {
            return summ + (summ * countPayments * (staff / 100));
        }
        else {
            let i = staff / 100;
            let k = (i * Math.pow(1 + i, countPayments)) / (Math.pow(1 + i, countPayments) - 1);
            return k * summ;
        }
    }

    getPaymentStr(periodType) {
        let paymentType;
        switch (periodType) {
            case 'day':
                paymentType = 'Единовременный платеж';
                break;
            case 'week':
                paymentType = 'Еженедельный платеж';
                break;
            case 'month':
                paymentType = 'Ежемесечный платеж';
                break;
            default:
                paymentType = 'Платеж'
                break;
        }
        return paymentType;
    }

    getPercent(staff, periodType) {
        switch(periodType) {
            case 'day':
                return staff * 365 / 12 / 100;
            case 'week':
                return staff / 7 * 365 / 12 / 100;
            case 'month':
                return staff / 100;
        }
    }

    periodToStrDay(value) {
        let e = Math.trunc(value);
        

        let r = ''.concat(e, ' ');
        if (e > 10 && e < 20) {
            r += 'дней';
        }
        else {
            let a = e % 10;
            r += a == 1 ? 'день' : a > 1 && a <= 4 ? 'дня' : 'дней';
        }
        return r;
    }

    periodToStrWeek(value) {
        let e = Math.trunc(value);
        

        let r = ''.concat(e, ' ');
        if (e > 10 && e < 20) {
            r += 'недель';
        }
        else {
            let a = e % 10;
            r += a == 1 ? 'неделя' : a > 1 && a <= 4 ? 'недели' : 'недель';
        }
        return r;
    }

    periodToStrMonth(value) {
        let e = Math.trunc(value)
            , modMonth = e % 12
            , years = (e - modMonth) / 12
            , a = years % 10
            , r = "";
        if (years > 0)
        {
            r = "".concat(years, " ");
            r += 1 == a ? "год" : 1 < a && a <= 4 ? "года" : "лет";
        } 
        if (modMonth > 0) {
            if (years > 0)
            {
                r += " ";
            }
            r += "".concat(modMonth, " месяц")
            if (modMonth >= 2 && modMonth <= 4) {
                r += "а";
            }
            else if (modMonth > 4) {
                r += "ев";
            }
        }
        return r;
    }

}