<template>
    <div style="max-width: 600px; margin-left: auto;margin-right: auto">
      <div class="row d-flex justify-content-end">
            <div class="p-2">
                <label class="col-form-label">Тип займа: <span class="text-info"><strong> {{calc.category}}</strong></span></label>
            </div>
            <div class="p-2" style="width:218px">
                <select v-model="calc" class="form-control" @change="changeCalc">
                    <optgroup v-for="g in calcGroups" :key="g.category" :label="g.category">
                        <option v-for="calc in g.calcs" :key=calc.name :value="calc">{{calc.name}}</option>
                    </optgroup>
                </select>
            </div>
        </div>
        <div class="row d-flex justify-content-around flex-nowrap">
            <div class="p-2" style="width: 55px;">
                <label class="col-form-label text-success"><strong>Сумма:</strong> </label>
            </div>
            <div class="p-2 flex-fill">
                <slider :min="calc.summMin" :max="calc.summMax" :step="calc.summStep" v-model="summ" style="padding-top: 15px; padding-left: 10px"></slider>
            </div>
            <format-textbox class="p-2" v-model="summ" :formatValue="summ + ' руб.'" :minValue="calc.summMin" :maxValue="calc.summMax"></format-textbox>
        </div>
        <div class="row d-flex justify-content-around flex-nowrap">
            <div class="p-2" style="width: 55px;">
                <label class="col-form-label text-success"><strong>Срок:</strong></label>
            </div>
            <div class="p-2 flex-fill">
                <slider :min="calc.periodMin" :max="calc.periodMax" :step="1" v-model="period" style="padding-top: 15px; padding-left: 10px"></slider>
            </div>
            <format-textbox class="p-2" v-model="period" :formatValue="periodStr" :minValue="calc.periodMin" :maxValue="calc.periodMax"></format-textbox>
        </div>
        <div class="row d-flex justify-content-center flex-nowrap">
            <div class="p-2">
                <label class="col-form-label">{{paymentType}}:</label>
            </div>
            <div class="p-2">
                <label class="col-form-label text-info"><strong>{{payment | format}} руб.</strong> </label>
            </div>
        </div>
    </div>
</template>
<script>

import Slider from './Slider.vue';
import FormatTextbox from './FormatTextbox.vue';
import calcConfig from "../calcConfig";
import calcServiceType from '../calcService';
let calcService = new calcServiceType();

export default {
  components: {
    Slider, 
    FormatTextbox
  },
  data() {
    return {
      calc: null,
      calcConfig: calcConfig,
      summ: 0,
      period: 0
    };
  },
  created() {
    this.calc = calcConfig[0];
    this.summ = this.calc.summMin;
    this.period = this.calc.periodMin;
  },
  mounted() {
    
  },
  methods:{
    changeCalc() {
      //this.calc = calc;
      this.summ = this.calc.summMin;
      this.period = this.calc.periodMin;
    }
  },
  computed:{
    periodStr(){
      return calcService.periodToStr(this.period, this.calc.periodType);
    },
    payment(){
      let payment = calcService.getPayment(this.summ, this.period, this.calc.staff, this.calc.periodType);
      return Math.round(payment);
    },
    paymentType(){
      return calcService.getPaymentStr(this.calc.periodType);
    },
    calcGroups(){
        let a = this.calcConfig.map(i => { return { category: i.category }});

        var groups = [];
        a.forEach(function(item) {
            if(groups.find(i => i.category == item.category) == null) {
                groups.push(item);
            }
        });

        groups.forEach(cat => {
            cat.calcs = this.calcConfig.filter(i => i.category == cat.category);
        });
        return groups;
    }
  },
  filters: {
      format: function (value) {
                if (!value) {
                    return '';
                }
                value = value.toString();
                var formatedValue = '';
                for (var i = 0; i < value.length; i++) {
                    if (i > 0 && (value.length - i) % 3 === 0) {
                        formatedValue += ' ';
                    }

                    formatedValue += value[i];
                }
                return formatedValue;
            }
  }
}
</script>