export default [
    {
        category: 'Беззалоговый',
        name: 'До 15 000 рублей',
        periodType: 'day',
        periodMin: 2,
        periodMax: 30,
        summMin: 2000,
        summMax: 15000,
        staff: 1,
        summStep: 1000
    },
    {
        category: 'Беззалоговый',
        name: 'До 30 000 рублей',
        periodType: 'week',
        periodMin: 4,
        periodMax: 52,
        summMin: 16000,
        summMax: 30000,
        staff: 6,
        summStep: 1000
    },
    {
        category: 'Беззалоговый',
        name: 'До 1 000 000 рублей',
        periodType: 'month',
        periodMin: 1,
        periodMax: 36,
        summMin: 31000,
        summMax: 1000000,
        staff: 5,
        summStep: 10000
    },
    {
        category: 'Займ под залог автомобиля',
        name: 'До 1 000 000 рублей',
        periodType: 'month',
        periodMin: 1,
        periodMax: 36,
        summMin: 10000,
        summMax: 1000000,
        staff: 3,
        summStep: 10000
    },
    {
        category: 'Займ под залог недвижимости',
        name: 'До 3 000 000 рублей',
        periodType: 'month',
        periodMin: 1,
        periodMax: 60,
        summMin: 10000,
        summMax: 3000000,
        staff: 3,
        summStep: 10000
    },
    {
        category: 'Перезалог недвижимости',
        name: 'До 3 000 000 рублей',
        periodType: 'month',
        periodMin: 1,
        periodMax: 60,
        summMin: 10000,
        summMax: 3000000,
        staff: 3,
        summStep: 10000
    }
    
    
]